import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { logoutRequest } from "authConfig";

const LoginButton = ({ account }) => {
  const { instance } = useMsal();

  return (
    <div className="logout-button">
      <Button
        variant="contained"
        size="large"
        onClick={async () =>
          await instance.logoutRedirect({ account: account, ...logoutRequest })
        }
      >
        Sign out
      </Button>
    </div>
  );
};

export default LoginButton;
