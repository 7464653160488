import { Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CachingFailed = ({ cancelFunction }) => {
  return (
    <div
      className="caching-failed"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>Failed to save and upload photo</h1>
      <p>
        The photo could not be cached or uploaded, please check device storage
        and try again.
      </p>
      <Fab
        color="white"
        aria-label="gallery"
        onClick={cancelFunction}
        sx={{ m: 2 }}
      >
        <CloseIcon />
      </Fab>
    </div>
  );
};

export default CachingFailed;
