import "./stylesheet.css";
import LoginButton from "components/LoginButton";

const LoginPage = () => {
  return (
    <div className="login-page">
      <img
        src={process.env.PUBLIC_URL + "/logo200.png"}
        id="big-logo"
        alt="Logo"
      />
      <LoginButton />
    </div>
  );
};

export default LoginPage;
