import { Fab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";

const PreviewBar = ({ acceptFunction, rejectFunction }) => {
  return (
    <div
      className="preview-bar"
      id="bottom-bar"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fab
        color="white"
        aria-label="back"
        onClick={rejectFunction}
        sx={{ m: 2 }}
      >
        <ArrowBackIcon />
      </Fab>
      <Fab
        color="white"
        aria-label="accept"
        onClick={acceptFunction}
        sx={{ m: 2 }}
      >
        <DoneIcon />
      </Fab>
    </div>
  );
};

export default PreviewBar;
