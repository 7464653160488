export const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: redirectUrl,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const logoutRequest = {
  postLogoutRedirectUri: redirectUrl,
};

export const lambdaEndpoint = process.env.REACT_APP_CLOUD_FUNCTION;

export const sentryEndpoint = process.env.REACT_APP_SENTRY;
