import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "pages/global.css";
import "./stylesheet.css";
import CameraFeed from "components/CameraFeed";
import CameraBar from "components/CameraBar";
import useOfflineStatus from "hooks/useOfflineStatus";
import { QuestionMark } from "@mui/icons-material";
import { Fab } from "@mui/material";

function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else {
    browserName = "Your browser";
  }

  return browserName;
}
const Camera = () => {
  const videoRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(1);
  const [cameraAvailable, setCameraAvailable] = useState(true);
  const navigate = useNavigate();
  const browserName = getBrowserName();

  useOfflineStatus();

  const redirectToHelp = () => {
    navigate("/help");
  };

  // Reset zoom to 1 on page load
  useEffect(() => {
    setZoomLevel(1);
  }, []);

  return (
    <div className="camera">
      {!cameraAvailable && (
        <div className={"camera-warning"}>
          <p>
            Cannot access the camera, try restarting the app. If the issue
            persists, try resetting the camera permissions by going to:
          </p>
          <p>
            Settings &gt; Apps &gt; {browserName} &gt; Permissions &gt; Camera
          </p>
        </div>
      )}

      <CameraFeed
        videoRef={videoRef}
        zoomLevel={zoomLevel}
        onZoomChange={setZoomLevel}
        setMinZoom={setMinZoom}
        setMaxZoom={setMaxZoom}
        setCameraAvailable={setCameraAvailable}
      />

      <CameraBar
        videoRef={videoRef}
        redirectUrl="/preview"
        zoomLevel={zoomLevel}
        onZoomChange={setZoomLevel}
        minZoom={minZoom}
        maxZoom={maxZoom}
        cameraAvailable={cameraAvailable}
      />
      <Fab
        color="white"
        aria-label="help"
        onClick={redirectToHelp}
        sx={{
          position: "fixed",
          top: "1rem",
          right: "1rem",
        }}
      >
        <QuestionMark />
      </Fab>
    </div>
  );
};

export default Camera;
