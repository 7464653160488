import "react-image-gallery/styles/css/image-gallery.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import * as Sentry from "@sentry/react";
import ImageGallery from "react-image-gallery";

import "pages/global.css";
import "./stylesheet.css";
import PreviewBar from "components/PreviewBar";
import { uploadPhoto } from "utils/upload";
import useOfflineStatus from "hooks/useOfflineStatus";
import WarningNotifier from "components/WarningNotifier";
import { deleteImageFromIndexedDB, savePhoto } from "utils/localStorage";
import { loginRequest, logoutRequest } from "authConfig";
import UploadStatus from "components/UploadStatus";

const Preview = () => {
  const { instance } = useMsal();
  const username = instance.getActiveAccount().username;
  const location = useLocation();
  const navigate = useNavigate();
  const offline = useOfflineStatus();

  const filename_root = `${username}/${Date.now()}`;
  const filenames = location.state.photoUrls.map(
    (_, i) => `${filename_root}_${i}`,
  );

  const photos = location.state.photos;
  const photoUrls = location.state.photoUrls;

  const images = [];
  for (let i = 0; i < photoUrls.length; i++) {
    images[i] = {
      original: photoUrls[i],
      thumbnail: photoUrls[i],
    };
  }

  async function savePhotos(photos, filenames) {
    for (let i = 0; i < filenames.length; i++) {
      await savePhoto(photos[i], filenames[i]);
    }
  }

  async function uploadPhotos(token, photos) {
    for (let i = 0; i < photos.length; i++) {
      if (!(await uploadPhoto(token, photos[i]))) {
        return false;
      }
    }
    return true;
  }

  async function deleteImagesFromDB(filenames) {
    for (let i = 0; i < filenames.length; i++) {
      await deleteImageFromIndexedDB(filenames[i]);
    }
  }

  const [uploading, setUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [cachingFailed, setCachingFailed] = useState(false);

  const attemptUpload = async () => {
    setUploading(true);

    if (offline) {
      setUploadFailed(true);
    }

    await instance
      .acquireTokenSilent(loginRequest)
      .then(async (tokenResponse) => {
        const uploaded = await uploadPhotos(tokenResponse.idToken, photos);
        if (uploaded) {
          await deleteImagesFromDB(filenames);
          navigateBack();
        } else {
          setUploadFailed(true);
        }

        return uploaded;
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await instance.logoutRedirect(logoutRequest);
        } else {
          Sentry.captureException(error);
        }
      });
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="preview-body">
      {uploading ? (
        <UploadStatus
          uploadFailed={uploadFailed}
          cachingFailed={cachingFailed}
          cancelFunction={navigateBack}
        />
      ) : (
        <div className="preview">
          <WarningNotifier
            offline={offline}
            message="You are offline! Images will be saved and uploaded when you have internet."
          />
          <ImageGallery
            items={images}
            className={"image-gallery"}
            originalClass={"image-gallery-image"}
            infinite={false}
            showPlayButton={false}
          />
          ;
          <PreviewBar
            acceptFunction={async () => {
              savePhotos(photos, filenames).catch(() => setCachingFailed(true));
              await attemptUpload();
            }}
            rejectFunction={navigateBack}
          />
        </div>
      )}
    </div>
  );
};

export default Preview;
