import * as Sentry from "@sentry/react";

// Function to delete an image from IndexedDB based on its key
export const deleteImageFromIndexedDB = (key) => {
  return new Promise((resolve, reject) => {
    // Open the "ImageCache" IndexedDB database
    const request = indexedDB.open("ImageCache", 1);
    request.onerror = (event) => {
      console.log("Error opening IndexedDB:", event.target.error);
      // Capture the error using Sentry for monitoring
      Sentry.captureException(event.target.error);
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      // Create a transaction for the "images" object store and delete the specified key
      const transaction = db.transaction(["images"], "readwrite");
      const objectStore = transaction.objectStore("images");
      const deleteRequest = objectStore.delete(key);

      deleteRequest.onsuccess = () => {
        console.log(`Image with key ${key} deleted from IndexedDB.`);
        resolve();
      };

      deleteRequest.onerror = (event) => {
        console.log("Error deleting image from IndexedDB:", event.target.error);
        // Capture the error using Sentry for monitoring
        Sentry.captureException(event.target.error);
        reject(event.target.error);
      };
    };
  });
};

// Function to find all local items in the IndexedDB
export const findLocalItems = () => {
  return new Promise((resolve, reject) => {
    // Open the "ImageCache" IndexedDB database
    const request = indexedDB.open("ImageCache", 1);
    request.onerror = (event) => {
      console.log("Error opening IndexedDB:", event.target.error);
      // Capture the error using Sentry for monitoring
      Sentry.captureException(event.target.error);
      reject(event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      // Create an "images" object store if it doesn't exist during database upgrade
      if (!db.objectStoreNames.contains("images")) {
        db.createObjectStore("images", { autoIncrement: false });
      }
    };

    const results = [];
    request.onsuccess = (event) => {
      const db = event.target.result;
      // Create a transaction for the "images" object store and retrieve items using a cursor
      const objectStore = db
        .transaction(["images"], "readwrite")
        .objectStore("images");
      const cursorRequest = objectStore.openCursor();

      cursorRequest.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          results.push({ key: cursor.key, val: cursor.value });
          cursor.continue();
        } else {
          console.log("Retrieved all images!");
          resolve(results);
        }
      };

      cursorRequest.onerror = (event) => {
        // Capture the error using Sentry for monitoring
        Sentry.captureException(event.target.error);
        reject(event.target.error);
      };
    };
  });
};

// Function to save a photo to IndexedDB with a specified filename
export const savePhoto = (photo, filename) => {
  return new Promise((resolve, reject) => {
    // Check if the browser supports persistent storage for the IndexedDB
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then();
    }

    // Open the "ImageCache" IndexedDB database
    const request = window.indexedDB.open("ImageCache", 1);
    request.onerror = (event) => {
      console.log("Error opening IndexedDB:", event.target.error);
      Sentry.captureException(event.target.error);
      reject(event.target.error);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      // Create an "images" object store if it doesn't exist during database upgrade
      db.createObjectStore("images", { autoIncrement: false });
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      // Create a transaction for the "images" object store and add the photo with the filename
      const transaction = db.transaction(["images"], "readwrite");
      const objectStore = transaction.objectStore("images");
      objectStore.add(photo, filename);

      transaction.onabort = (event) => {
        if (event.target.error.name === "QuotaExceededError") {
          reject(event.target.error);
        }
      };

      transaction.onsuccess = () => {
        console.log(`File saved as ${filename}`);
        resolve(true);
      };

      transaction.onerror = (event) => {
        console.log("Error adding photo to IndexedDB:", event.target.error);
        // Check if there is enough space left on the device.
        if (event.target.error.name !== "QuotaExceededError") {
          // Capture other errors using Sentry for monitoring
          Sentry.captureException(event.target.error);
        }
        reject(event.target.error);
      };
    };
  });
};
