import React, { useState } from "react";
import "./stylesheet.css";

export default function Help() {
  const [visible, setVisible] = useState({ q1: false, q2: false, q3: false });

  const toggleVisibility = (question) => {
    setVisible((prevState) => ({
      ...prevState,
      [question]: !prevState[question],
    }));
  };

  return (
    <div className="help-container">
      <h1>Nápověda (testovací režim)</h1>
      <p>
        Aplikace umožňuje nahrát vizitky z mobilního zařízení do P1. U každé
        vizitky P1 rozpozná jméno, firmu a kontaktní údaje a během pár minut ji
        uvidíte v P1 na počítači v okně "Moje soubory". Odtud můžete vizitky
        přiřazovat novým nebo již existujícím kontaktům či zákazníkům v P1.
      </p>
      <p>
        Pro nahrání fotoaparátem:
        <ul>
          <li>
            Natočte zařízení tak, aby byla hlavní strana vizitky vidět celá.
          </li>
          <li>Pro přiblížení použijte dva prsty.</li>
          <li>
            Foťte vždy jen hlavní stranu vizitky. Detaily z druhé strany půjdou
            přidat přímo v P1.
          </li>
        </ul>
      </p>
      <p>
        Pro nahrání jedné nebo více vizitek z úložiště zařízení stiskněte
        tlačítko galerie vlevo.
      </p>
      <p>
        Pokud nahrávání selže, aplikace to za chvíli zkusí znovu, případně když
        se aplikace znovu spustí.
      </p>
      <h2>Otázky a odpovědi</h2>
      <ul>
        <li onClick={() => toggleVisibility("q1")}>
          Jak dát odkaz na aplikaci na plochu mobilního zařízení?
          {visible.q1 && (
            <p className="answer">
              Záleží na zařízení. Např. v Chromu na Androidu stiskněte tři tečky
              vpravo nahoře, pak "Instalovat aplikaci" nebo "Přidat na plochu."
            </p>
          )}
        </li>
        <li onClick={() => toggleVisibility("q2")}>
          Funguje aplikace i na iOS?
          {visible.q2 && (
            <p className="answer">
              Pravděpodobně ano, i když poslední verze nebyla testována.
            </p>
          )}
        </li>
        <li onClick={() => toggleVisibility("q3")}>
          Jak dlouho jsou uloženy nezpracované vizitky?
          {visible.q3 && (
            <p className="answer">30 dní, pak je systém automaticky maže.</p>
          )}
        </li>
      </ul>
    </div>
  );
}
