import { Fab } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const UploadFailed = ({ cancelFunction }) => {
  return (
    <div
      className="upload-failed"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1>Upload Failed</h1>
      <p>The photo will be uploaded in the background when you have internet</p>
      <Fab
        color="white"
        aria-label="gallery"
        onClick={cancelFunction}
        sx={{ m: 2 }}
      >
        <ArrowForwardIcon />
      </Fab>
    </div>
  );
};

export default UploadFailed;
