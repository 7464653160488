import React, { useEffect, useRef, useState } from "react";

const CameraFeed = ({
  videoRef,
  zoomLevel,
  onZoomChange,
  setMinZoom,
  setMaxZoom,
  setCameraAvailable,
}) => {
  // State to store initial touch distance and zoom level
  const [initialDistance, setInitialDistance] = useState(null);
  const [initialZoom, setInitialZoom] = useState(zoomLevel);
  // Ref to check if it's the initial component mount
  const initialMount = useRef(true);
  // Zoom factor for touch-based zoom
  const zoomFactor = 0.01;

  // Event handler for the start of a touch gesture
  const handleTouchStart = (event) => {
    if (event.touches.length >= 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      // Calculate the initial distance between two touches
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY,
      );
      setInitialDistance(distance);
      setInitialZoom(zoomLevel);
    }
  };

  // Event handler for touch movement to implement zoom
  const handleTouchMove = (event) => {
    if (event.touches.length >= 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      // Calculate the current distance between two touches
      const distance = Math.hypot(
        touch2.clientX - touch1.clientX,
        touch2.clientY - touch1.clientY,
      );
      // Calculate the zoom delta based on the distance change
      const zoomDelta = (distance - initialDistance) * zoomFactor;
      // Calculate the new zoom level and ensure it stays within bounds
      const newZoom = Math.max(0.1, initialZoom + zoomDelta);
      onZoomChange(newZoom);
    }
  };

  // Effect to set up initial camera configuration
  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    const mediaStream = video.srcObject;
    if (!mediaStream) {
      return;
    }

    const videoTrack = mediaStream.getVideoTracks()[0];
    if (!videoTrack) {
      return;
    }

    const zoomCapabilities = videoTrack.getCapabilities().zoom;
    if (!zoomCapabilities) {
      return;
    }

    const maxZoom = zoomCapabilities.max || 1;
    setMaxZoom(maxZoom);
    const minZoom = zoomCapabilities.min || 0;
    setMinZoom(minZoom);

    // Ensure the zoom level stays within capabilities
    const clampedZoom = Math.max(minZoom, Math.min(zoomLevel, maxZoom));

    // Apply the zoom constraints to the video track
    videoTrack.applyConstraints({ advanced: [{ zoom: clampedZoom }] }).catch();
  }, [videoRef, zoomLevel, setMaxZoom, setMinZoom]);

  // Effect for handling camera focus and visibility changes
  useEffect(() => {
    let video = videoRef.current;

    const handleBeforeUnload = () => {
      if (video && video.srcObject) {
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        setCameraAvailable(false);
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handleBeforeUnload();
      } else if (
        document.visibilityState === "visible" &&
        !initialMount.current
      ) {
        handleFocus();
      }
    };

    const handleFocus = () => {
      // Re-access the camera when the tab is focused
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment",
            aspectRatio: 1,
            width: { ideal: 1000 },
            height: { ideal: 1000 },
            zoom: true,
          },
        })
        .then(async (stream) => {
          video.srcObject = stream;
          // Wait for the loadedmetadata event before playing the video
          video.addEventListener("loadedmetadata", () => {
            video.play().catch((err) => {
              console.error("Error playing video:", err);
            });
          });
          setCameraAvailable(true);
        })
        .catch((err) => {
          console.error(err);
          setCameraAvailable(false);
        });
    };

    if (initialMount) {
      handleFocus();
      initialMount.current = false;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      handleBeforeUnload();
    };
  }, [videoRef, initialMount, setCameraAvailable]);

  return (
    <div
      className="camera-feed"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <video
        ref={videoRef}
        style={{
          width: "auto",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};

export default CameraFeed;
