import { lambdaEndpoint } from "authConfig";
import { deleteImageFromIndexedDB, findLocalItems } from "./localStorage";
import { toast } from "react-toastify";

export const uploadPhoto = async (token, photo) => {
  try {
    const searchParams = new URLSearchParams({
      token: token,
    });
    const urlResponse = await fetch(lambdaEndpoint + "?" + searchParams, {
      method: "GET",
    });

    const uploadUrl = await urlResponse.text();

    if (urlResponse.ok) {
      const uploadResponse = await fetch(uploadUrl, {
        method: "PUT",
        body: photo,
      });

      if (uploadResponse.ok) {
        console.log("File uploaded successfully!");
        toast.success("File uploaded successfully");
        return true;
      } else {
        console.log(
          "Error uploading file: ",
          uploadResponse.status,
          urlResponse.statusText,
        );
        toast.error("Failed to upload the file");
        return false;
      }
    } else {
      console.log(
        "Error uploading file: ",
        urlResponse.status,
        urlResponse.statusText,
      );
      toast.error("Failed to upload the file");
      return false;
    }
  } catch (error) {
    console.log("Error uploading file: ", error);
    return false;
  }
};

export const batchUpload = async (token) => {
  const images = await findLocalItems();
  for (let i = 0; i < images.length; i++) {
    if (await uploadPhoto(token, images[i].val)) {
      await deleteImageFromIndexedDB(images[i].key);
    }
  }
};
