import React from "react";
import { Alert } from "@mui/material";

function WarningNotifier({ offline, message }) {
  return (
    <div className="isOnline">
      {offline && <Alert severity="warning">{message}</Alert>}
    </div>
  );
}

export default WarningNotifier;
