import React, { useState } from "react";
import { createTheme, Slider, ThemeProvider } from "@mui/material";

const ZoomSlider = ({ zoomLevel, onZoomChange, minZoom, maxZoom }) => {
  const [isSliderActive, setIsSliderActive] = useState(false);

  const muiTheme = createTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          backgroundColor: "blue",
        },
        track: {
          backgroundColor: "white",
        },
        rail: {
          backgroundColor: "black",
        },
      },
    },
  });

  const handleChange = (event, newValue) => {
    setIsSliderActive(true);
    onZoomChange(newValue);
  };

  const handleSliderRelease = () => {
    setIsSliderActive(false);
  };

  return (
    <div
      className="zoom-slider"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(zoomLevel > minZoom || isSliderActive) && (
        <ThemeProvider theme={muiTheme}>
          <Slider
            step={0.01} // Make the slider 'continuous'
            min={minZoom}
            max={maxZoom}
            value={zoomLevel}
            onChange={handleChange}
            onChangeCommitted={handleSliderRelease}
            sx={{
              "& .MuiSlider-thumb": {
                color: "#2196f3",
              },
              "& .MuiSlider-track": {
                color: "white",
              },
              "& .MuiSlider-rail": {
                color: "black",
              },
            }}
          />
        </ThemeProvider>
      )}
    </div>
  );
};

export default ZoomSlider;
