import { CircularProgress } from "@mui/material";

const UploadingIndicator = () => {
  return (
    <div
      className="uploading-indicator"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <p>Your photo is uploading!</p>
    </div>
  );
};

export default UploadingIndicator;
