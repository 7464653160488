import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

import "pages/global.css";
import "./stylesheet.css";
import LogoutButton from "components/LogoutButton";

const Settings = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();

    if (currentAccount) {
      setUserName(currentAccount.name);
      setUserEmail(currentAccount.username);
    }
  }, [instance]);

  const onClosePressed = () => {
    navigate(-1);
  };

  return (
    <div className="settings">
      <IconButton size="large" id="close" onClick={onClosePressed}>
        <CloseIcon size="large" />
      </IconButton>
      <div className="settings-content">
        <img
          src={process.env.PUBLIC_URL + "/logo200.png"}
          id="big-logo"
          alt="Logo"
        />
        <p>You are logged in as:</p>
        <p>{userName}</p>
        <p>{userEmail}</p>
        <LogoutButton account={instance.getActiveAccount()} />
      </div>
    </div>
  );
};

export default Settings;
