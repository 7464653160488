import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, logoutRequest } from "../authConfig";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import * as Sentry from "@sentry/react";

const useOfflineStatus = () => {
  const [offline, setOffline] = useState(!navigator.onLine);

  const { instance, inProgress } = useMsal();
  let idToken = useRef(null);

  // useEffect for initial mount and updates
  useEffect(() => {
    const fetchToken = async () => {
      if (
        inProgress === InteractionStatus.None &&
        instance.getActiveAccount()
      ) {
        try {
          const tokenResponse = await instance.acquireTokenSilent(loginRequest);
          idToken.current = tokenResponse.idToken;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            await instance.logoutRedirect(logoutRequest);
          } else {
            Sentry.captureException(error);
          }
        }
      }
    };

    const notifyServiceWorker = () => {
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "batchUpload",
          idToken: idToken.current,
        });
      }
    };

    const handleOnline = async () => {
      await fetchToken();
      setOffline(false);
      notifyServiceWorker();
    };

    const handleOffline = () => {
      setOffline(true);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    if (!offline && inProgress === InteractionStatus.None) {
      handleOnline().catch((error) => Sentry.captureException(error));
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [offline, idToken, instance, inProgress]);

  return offline;
};

export default useOfflineStatus;
