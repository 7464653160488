import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";

import { loginRequest } from "authConfig";
import { InteractionStatus } from "@azure/msal-browser";

const LoginButton = () => {
  const { instance, inProgress } = useMsal();

  // Check that no other interaction is in progress before invoking loginPopup to avoid an interaction_in_progress error
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/errors.md#interaction_in_progress
  const initializeSignIn = async () => {
    try {
      if (inProgress === InteractionStatus.None) {
        await instance.loginRedirect(loginRequest).then((response) => {});
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <div className="login-button">
      <Button variant="contained" size="large" onClick={initializeSignIn}>
        Sign in
      </Button>
    </div>
  );
};

export default LoginButton;
