import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import LoginPage from "./pages/LoginPage";
import Camera from "./pages/Camera";
import Preview from "./pages/Preview";
import Settings from "./pages/Settings";
import { loginRequest, logoutRequest } from "./authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Help from "./pages/Help";

const App = ({ msalInstance }) => {
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const account = msalInstance.getActiveAccount();
        if (!account) return;

        await msalInstance.acquireTokenSilent(loginRequest);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance.logoutRedirect(logoutRequest);
        } else {
          console.error("Error checking token validity:", error);
        }
      }
    };

    checkTokenValidity().then();
  }, [msalInstance]);

  return (
    <>
      <ToastContainer />
      <Suspense fallback={<div>Loading...</div>}>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <MsalProvider instance={msalInstance}>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Camera />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/help" element={<Help />} />
              </Routes>
            </BrowserRouter>
          </AuthenticatedTemplate>
        </MsalProvider>
      </Suspense>
    </>
  );
};

export default App;
