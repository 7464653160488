import UploadingIndicator from "./UploadingIndicator";
import UploadFailed from "./UploadFailed";
import CachingFailed from "./CachingFailed";

const UploadStatus = ({ uploadFailed, cachingFailed, cancelFunction }) => {
  if (cachingFailed && uploadFailed) {
    return <CachingFailed cancelFunction={cancelFunction} />;
  } else if (uploadFailed) {
    return <UploadFailed cancelFunction={cancelFunction} />;
  } else {
    return <UploadingIndicator />;
  }
};

export default UploadStatus;
