import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fab, ToggleButtonGroup } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CollectionsIcon from "@mui/icons-material/Collections";
import SettingsIcon from "@mui/icons-material/Settings";

import ZoomSlider from "./ZoomSlider";

const CameraBar = ({
  videoRef,
  redirectUrl,
  zoomLevel,
  onZoomChange,
  minZoom,
  maxZoom,
  cameraAvailable,
}) => {
  let navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [mode, setMode] = useState("business card");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setButtonDisabled(!cameraAvailable);
  }, [cameraAvailable]);

  const takePicture = () => {
    let video = videoRef.current;
    if (video == null) return;
    let photo = document.createElement("canvas");
    photo.width = video.videoWidth;
    photo.height = video.videoHeight;
    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, photo.width, photo.height);

    photo.toBlob(
      (blob) => {
        navigate(redirectUrl, {
          state: {
            photos: [blob],
            photoUrls: [photo.toDataURL("image/jpeg", 1)],
          },
        });
      },
      "image/jpeg",
      1,
    );
  };

  const handleFileChange = (event) => {
    const filePicker = event.target;

    if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
      console.log("No file selected.");
      return;
    }

    const files = Array.from(filePicker.files);
    const urlPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        fileReader.onerror = (e) => {
          reject(e);
        };
        fileReader.readAsDataURL(file);
      });
    });

    Promise.all(urlPromises)
      .then((photoUrls) => {
        navigate(redirectUrl, {
          state: {
            photos: files,
            photoUrls: photoUrls,
          },
        });
      })
      .catch((error) => {
        console.error("Error while reading files", error);
      });
  };

  const handleFileInput = () => {
    fileInputRef.current.click();
  };

  const handleMode = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  return (
    <div className="camera-bar" id="bottom-bar">
      <ZoomSlider
        zoomLevel={zoomLevel}
        onZoomChange={onZoomChange}
        minZoom={minZoom}
        maxZoom={maxZoom}
      />
      <div
        className="mode-select"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ToggleButtonGroup exclusive value={mode} onChange={handleMode}>
          <Fab variant="extended" value="business card" size="small">
            business card
          </Fab>
        </ToggleButtonGroup>
      </div>
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          type="file"
          accept="image/jpeg,image/png"
          multiple
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Fab
          color="white"
          aria-label="gallery"
          onClick={handleFileInput}
          sx={{ m: 2 }}
        >
          <CollectionsIcon />
        </Fab>
        <Fab
          color="white"
          aria-label="capture"
          onClick={takePicture}
          sx={{ m: 2 }}
          disabled={buttonDisabled}
        >
          <CircleIcon />
        </Fab>
        <Fab
          color="white"
          aria-label="capture"
          onClick={() => navigate("/settings")}
          sx={{ m: 2 }}
        >
          <SettingsIcon />
        </Fab>
      </div>
    </div>
  );
};

export default CameraBar;
